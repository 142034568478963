<template>
  <div class="app-container v">
    <el-form class="head-container e" label-position="top">
      <el-form-item label="商品分类" class="filter-item">
        <tree-picker v-model="query.categoryCode" url="api/category/tree" value-field="code" clearable placeholder="请选择分类" style="width: 180px;" />
      </el-form-item>

      <el-form-item label="商品名称" class="filter-item">
        <el-input v-model="query.goodsName" clearable style="width: 180px" />
      </el-form-item>

      <el-form-item class="filter-item flex">
        <el-button type="success" icon="el-icon-search" :loading="loading" @click="doQuery">统计</el-button>
        <el-button type="success" :loading="downloadLoading" @click="toDownload">导出库存明细</el-button>
      </el-form-item>

      <el-form-item class="filter-item">
        <el-alert type="info" show-icon :closable="false" title="计算公式：期初+工厂发货-终端销售±调整=净库存" style="width: auto; line-height: 1;"></el-alert>
      </el-form-item>
    </el-form>

    <el-card class="no-flex" body-style="padding: 0;" style="margin-bottom: 10px;" shadow="never" v-if="summary">
      <div class="h c">
        <div class="padding-10" style="width: 18%;">
          <div class="ta-c bold">期初</div>
          <div class="h ta-c gap-1x">
            <div style="width: 35%">
              <div class="fs-mini fc-g">数量</div>
              <div class="bold">{{summary.bopQuan.toFixed(2)}}</div>
            </div>
            <div style="width: 65%">
              <div class="fs-mini fc-g">期初库存成本</div>
              <div class="bold">{{$price(summary.bopAmount)}}</div>
            </div>
          </div>
        </div>
        <el-divider direction="vertical" />
        <div class="padding-10" style="width: 18%;">
          <div class="ta-c bold">工厂发货</div>
          <div class="h ta-c gap-1x">
            <div style="width: 35%">
              <div class="fs-mini fc-g">数量</div>
              <div class="bold">{{summary.deliveryQuan.toFixed(2)}}</div>
            </div>
            <div style="width: 65%">
              <div class="fs-mini fc-g">采购成本</div>
              <div class="bold">{{$price(summary.deliveryAmount)}}</div>
            </div>
          </div>
        </div>
        <el-divider direction="vertical" />
        <div class="padding-10" style="width: 27%;">
          <div class="ta-c bold">终端销售</div>
          <div class="h ta-c gap-1x">
            <div style="width: 25%">
              <div class="fs-mini fc-g">数量</div>
              <div class="bold">{{summary.retailQuan.toFixed(2)}}</div>
            </div>
            <div style="width: 37%">
              <div class="fs-mini fc-g">销售额</div>
              <div class="bold">{{$price(summary.retailSaleAmount)}}</div>
            </div>
            <div style="width: 37%">
              <div class="fs-mini fc-g">产品成本</div>
              <div class="bold">{{$price(summary.retailAmount)}}</div>
            </div>
          </div>
        </div>
        <el-divider direction="vertical" />
        <div class="padding-10" style="width: 18%;">
          <div class="ta-c bold">调整</div>
          <div class="h ta-c gap-1x">
            <div style="width: 35%">
              <div class="fs-mini fc-g">数量</div>
              <div class="bold">{{summary.changeQuan.toFixed(2)}}</div>
            </div>
            <div style="width: 65%">
              <div class="fs-mini fc-g">产品成本</div>
              <div class="bold">{{$price(summary.changeAmount)}}</div>
            </div>
          </div>
        </div>
        <el-divider direction="vertical" />
        <div class="padding-10" style="width: 18%;">
          <div class="ta-c bold">净库存</div>
          <div class="h ta-c gap-1x">
            <div style="width: 35%">
              <div class="fs-mini fc-g">数量</div>
              <div class="bold">{{summary.balanceQuan.toFixed(2)}}</div>
            </div>
            <div style="width: 65%">
              <div class="fs-mini fc-g">产品成本</div>
              <div class="bold">{{$price(summary.balanceAmount)}}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" border height="530">
      <!-- <el-table-column type="index" width="50" /> -->
      <el-table-column prop="erpCode" label="产品编码" min-width="120" />
      <el-table-column prop="name" label="产品名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="specs" label="规格" min-width="160" show-overflow-tooltip :formatter="$goodsSpecConvert" />
      <el-table-column label="期初" align="center">
        <el-table-column prop="bopQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="bopAmount" label="期初库存成本" min-width="100" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="工厂发货" align="center">
        <el-table-column prop="deliveryQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="deliveryAmount" label="采购成本" min-width="100" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="终端销售" align="center">
        <el-table-column prop="retailQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="retailSaleAmount" label="销售额" min-width="110" :formatter="$price" align="right" />
        <el-table-column prop="retailAmount" label="产品成本" min-width="100" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="调整" align="center">
        <el-table-column prop="changeQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="changeAmount" label="产品成本" min-width="100" :formatter="$price" align="right" />
      </el-table-column>
      <el-table-column label="净库存" align="center">
        <el-table-column prop="balanceQuan" label="数量" min-width="70" align="center" />
        <el-table-column prop="balanceAmount" label="产品成本" min-width="100" :formatter="$price" align="right" />
      </el-table-column>
    </el-table>
    <el-pagination align="center" :current-page="page + 1" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" @current-change="pageChange" @size-change="toQuery" :total="total" @ />
  </div>
</template>

<script>
import deptSelect from "../distributors/deptSelect";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";

export default {
  mixins: [initData],
  components: {
    deptSelect,
  },
  data() {
    return {
      query: {
        treeNodeId: null,
        treeNodeType: null,
        categoryCode: "",
        goodsName: "",
      },
      downloadLoading: false,
      size: 20,
      summary: null,
    };
  },
  methods: {
    beforeInit() {
      this.url = "@host:analysis;api/analy/stockReport/goods";
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          distributorId: this.$store.state.user.user.entId
        },
        this.query
      );
      if (this.page === 0) this.loadSummary();
      return true;
    },
    loadSummary() {
      request({
        url: "@host:analysis;api/analy/stockReport/total",
        method: "get",
        params: this.params,
      })
        .then((res) => {
          this.summary = res;
        })
        .catch((err) => {
          this.summary = null;
        });
    },
    doQuery() {
      this.page = 0;
      this.init();
    },
    toDownload() {
      this.downloadLoading = true;
      download("@host:analysis;api/analy/stockReport/download", this.params)
        .then((result) => {
          downloadFile(result, "净库存明细报表", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
  mounted() {
    this.doQuery();
  }
};
</script>